import type { FC } from 'react';
import React, { useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Tooltip from '@atlaskit/tooltip';
import CalendarIcon from '@atlaskit/icon/core/calendar';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { SPACE_CALENDARS, SPACE_CALENDARS_SUBCALENDAR } from '@confluence/named-routes';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { PremiumLozengeLoadable } from '@confluence/trial-components';
import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useRouteName } from '@confluence/route-manager';

type CalendarsLinkProps = {
	onClick: () => void;
};

export const CalendarsLink = (props: CalendarsLinkProps) => {
	const { edition } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);

	/**
	 * showTCNav:
	 * - Premium -> true, TC is a premium feature
	 * - Standard -> true, iff user is Org/Site Admin, otherwise, false (https://hello.atlassian.net/wiki/spaces/CE2/pages/3031965072/ETC+Standard+P18N+Technical+Spec)
	 * - Free -> true, TC upsell to all Free users (https://hello.atlassian.net/wiki/spaces/CE2/pages/2906634153/ETC+P18N+Technical+Spec)
	 */
	const showTCNav = edition === ConfluenceEdition.STANDARD ? isSiteAdmin : true;

	return showTCNav ? <CalendarsLinkInner {...props} /> : null;
};

const CalendarsLinkInner: FC<CalendarsLinkProps> = ({ onClick }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [spaceKey] = usePageSpaceKey();
	const isSelected = useRouteName(isOnSCalendarsRoute);

	return (
		<Tooltip position="top" content={<FormattedMessage {...i18n.calendarsTooltip} />}>
			<MenuLinkItem
				href={SPACE_CALENDARS.toUrl({ spaceKey })}
				onClick={onClick}
				elemBefore={<CalendarIcon label="" color="currentColor" />}
				isSelected={isSelected}
				data-vc="team-calendar-navigation"
				{...ssrPlaceholderIdProp}
			>
				<FormattedMessage {...i18n.calendars} />
				<PremiumLozengeLoadable />
			</MenuLinkItem>
		</Tooltip>
	);
};

const isOnSCalendarsRoute = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_CALENDARS.name || routeName === SPACE_CALENDARS_SUBCALENDAR.name,
};

const i18n = defineMessages({
	calendarsTooltip: {
		id: 'side-navigation.space-navigation.calendars.tooltip',
		defaultMessage: 'View calendar',
		description: 'Tooltip message on hover of calendars space link',
	},
	calendars: {
		id: 'side-navigation.space-navigation.calendars',
		defaultMessage: 'Calendars',
		description: 'Text for the Calendars navigation menu item',
	},
});
